.fc .fc-toolbar {
  flex-wrap: wrap;
  flex-direction: row !important;
}
.fc .fc-toolbar .fc-prev-button,
.fc .fc-toolbar .fc-next-button {
  display: inline-block;
}
[dir] .fc .fc-toolbar .fc-prev-button, [dir] .fc .fc-toolbar .fc-next-button {
  background-color: transparent;
  border-color: transparent;
}
.fc .fc-toolbar .fc-prev-button .fc-icon,
.fc .fc-toolbar .fc-next-button .fc-icon {
  color: #6e6b7b;
}
[dir] .fc .fc-toolbar .fc-prev-button:hover, [dir] .fc .fc-toolbar .fc-prev-button:active, [dir] .fc .fc-toolbar .fc-prev-button:focus, [dir] .fc .fc-toolbar .fc-next-button:hover, [dir] .fc .fc-toolbar .fc-next-button:active, [dir] .fc .fc-toolbar .fc-next-button:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
[dir=ltr] .fc .fc-toolbar .fc-prev-button {
  padding-left: 0 !important;
}
[dir=rtl] .fc .fc-toolbar .fc-prev-button {
  padding-right: 0 !important;
}
.fc .fc-toolbar .fc-toolbar-chunk:first-child {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
[dir] .fc .fc-toolbar .fc-button {
  padding: 0.438rem 0.5rem;
}
[dir] .fc .fc-toolbar .fc-button:active, [dir] .fc .fc-toolbar .fc-button:focus {
  box-shadow: none;
}
.fc .fc-toolbar .fc-button-group .fc-button {
  text-transform: capitalize;
}
[dir] .fc .fc-toolbar .fc-button-group .fc-button:focus {
  box-shadow: none;
}
.fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
  color: #0875d6;
}
[dir] .fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
  background-color: transparent;
  border-color: #0875d6;
}
.fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active, .fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
  color: #0875d6;
}
[dir] .fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active, [dir] .fc .fc-toolbar .fc-button-group .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
  background-color: rgba(8, 117, 214, 0.2) !important;
  border-color: #0875d6 !important;
}
[dir] .fc .fc-toolbar .fc-button-group .fc-button-primary.fc-sidebarToggle-button {
  border: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button-primary.fc-sidebarToggle-button i,
.fc .fc-toolbar .fc-button-group .fc-button-primary.fc-sidebarToggle-button svg {
  height: 21px;
  width: 21px;
  font-size: 21px;
}
.fc .fc-toolbar .fc-button-group .fc-sidebarToggle-button {
  color: #6e6b7b !important;
}
[dir] .fc .fc-toolbar .fc-button-group .fc-sidebarToggle-button {
  background-color: transparent !important;
}
[dir=ltr] .fc .fc-toolbar .fc-button-group .fc-sidebarToggle-button {
  padding-left: 0;
}
[dir=rtl] .fc .fc-toolbar .fc-button-group .fc-sidebarToggle-button {
  padding-right: 0;
}
[dir] .fc .fc-toolbar .fc-button-group .fc-sidebarToggle-button:not(.fc-prev-button):not(.fc-next-button):hover {
  background-color: transparent !important;
}
[dir=ltr] .fc .fc-toolbar .fc-button-group .fc-sidebarToggle-button + div {
  margin-left: 0;
}
[dir=rtl] .fc .fc-toolbar .fc-button-group .fc-sidebarToggle-button + div {
  margin-right: 0;
}
[dir] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button, [dir] .fc .fc-toolbar .fc-button-group .fc-listMonth-button {
  padding: 0.55rem 1.5rem;
}
[dir] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:last-child, [dir] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:first-child, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:last-child, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:first-child, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:last-child, [dir] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:first-child, [dir] .fc .fc-toolbar .fc-button-group .fc-listMonth-button:last-child, [dir] .fc .fc-toolbar .fc-button-group .fc-listMonth-button:first-child {
  border-radius: 0.358rem;
}
[dir=ltr] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:first-child, [dir=ltr] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:first-child, [dir=ltr] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:first-child, [dir=ltr] .fc .fc-toolbar .fc-button-group .fc-listMonth-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir=rtl] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:first-child, [dir=rtl] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:first-child, [dir=rtl] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:first-child, [dir=rtl] .fc .fc-toolbar .fc-button-group .fc-listMonth-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
[dir=ltr] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:last-child, [dir=ltr] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:last-child, [dir=ltr] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:last-child, [dir=ltr] .fc .fc-toolbar .fc-button-group .fc-listMonth-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
[dir=rtl] .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button:last-child, [dir=rtl] .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button:last-child, [dir=rtl] .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button:last-child, [dir=rtl] .fc .fc-toolbar .fc-button-group .fc-listMonth-button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[dir=ltr] .fc .fc-toolbar > * > :not(:first-child) {
  margin-left: 0rem;
}
[dir=rtl] .fc .fc-toolbar > * > :not(:first-child) {
  margin-right: 0rem;
}
[dir=ltr] .fc .fc-toolbar .fc-toolbar-title {
  margin-left: 0.25rem;
}
[dir=rtl] .fc .fc-toolbar .fc-toolbar-title {
  margin-right: 0.25rem;
}
.fc .fc-toolbar .fc--button:empty,
.fc .fc-toolbar .fc-toolbar-chunk:empty {
  display: none;
}
[dir] .fc tbody td, [dir] .fc thead th {
  border-color: #ebe9f1;
}
[dir=ltr] .fc tbody td.fc-col-header-cell, [dir=ltr] .fc thead th.fc-col-header-cell {
  border-right: 0;
  border-left: 0;
}
[dir=rtl] .fc tbody td.fc-col-header-cell, [dir=rtl] .fc thead th.fc-col-header-cell {
  border-left: 0;
  border-right: 0;
}
.fc .fc-view-harness {
  min-height: 650px;
}
[dir] .fc .fc-scrollgrid-section-liquid > td {
  border-bottom: 0;
}
.fc .fc-daygrid-event-harness .fc-event {
  font-size: 0.8rem;
  font-weight: 600;
}
[dir] .fc .fc-daygrid-event-harness .fc-event {
  padding: 0.25rem 0.5rem;
}
[dir] .fc .fc-daygrid-event-harness + .fc-daygrid-event-harness {
  margin-top: 0.3rem;
}
[dir] .fc .fc-daygrid-day-bottom {
  margin-top: 0.3rem;
}
[dir] .fc .fc-daygrid-day {
  padding: 5px;
}
.fc .fc-daygrid-day .fc-daygrid-day-top {
  flex-direction: row;
}
.fc .fc-daygrid-day-number,
.fc .fc-timegrid-slot-label-cushion,
.fc .fc-list-event-time {
  color: #6e6b7b;
}
[dir] .fc .fc-day-today {
  background: #f8f8f8 !important;
  background-color: #f8f8f8 !important;
}
[dir] .fc .fc-timegrid .fc-scrollgrid-section .fc-col-header-cell, [dir] .fc .fc-timegrid .fc-scrollgrid-section .fc-timegrid-axis {
  border-color: #ebe9f1;
}
[dir=ltr] .fc .fc-timegrid .fc-scrollgrid-section .fc-col-header-cell, [dir=ltr] .fc .fc-timegrid .fc-scrollgrid-section .fc-timegrid-axis {
  border-left: 0;
  border-right: 0;
}
[dir=rtl] .fc .fc-timegrid .fc-scrollgrid-section .fc-col-header-cell, [dir=rtl] .fc .fc-timegrid .fc-scrollgrid-section .fc-timegrid-axis {
  border-right: 0;
  border-left: 0;
}
[dir] .fc .fc-timegrid .fc-scrollgrid-section .fc-timegrid-axis {
  border-color: #ebe9f1;
}
.fc .fc-timegrid .fc-timegrid-axis.fc-scrollgrid-shrink .fc-timegrid-axis-cushion {
  text-transform: capitalize;
  color: #b9b9c3;
}
.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot {
  height: 3rem;
}
[dir] .fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot .fc-timegrid-slot-label-frame {
  text-align: center;
}
.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
  text-transform: uppercase;
}
.fc .fc-timegrid .fc-timegrid-divider {
  display: none;
}
[dir] .fc .fc-list {
  border-color: #ebe9f1;
}
[dir] .fc .fc-list .fc-list-day-cushion {
  background: #f8f8f8;
}
[dir] .fc .fc-list .fc-list-event:hover td {
  background-color: #f8f8f8;
}
[dir] .fc .fc-list .fc-list-event td {
  border-color: #ebe9f1;
}
.app-calendar {
  position: relative;
}
[dir] .app-calendar {
  border-radius: 0.428rem;
}
.app-calendar .app-calendar-sidebar {
  position: absolute;
  width: 18rem;
  height: 100%;
  z-index: 5;
  flex-basis: 18rem;
  transition: all 0.2s, background 0s, border 0s;
}
[dir] .app-calendar .app-calendar-sidebar {
  background-color: #fff;
}
[dir=ltr] .app-calendar .app-calendar-sidebar {
  left: calc(-18rem - 1.2rem);
  border-right: 1px solid #ebe9f1;
}
[dir=rtl] .app-calendar .app-calendar-sidebar {
  right: calc(-18rem - 1.2rem);
  border-left: 1px solid #ebe9f1;
}
[dir=ltr] .app-calendar .app-calendar-sidebar.show {
  left: 0;
}
[dir=rtl] .app-calendar .app-calendar-sidebar.show {
  right: 0;
}
.app-calendar .app-calendar-sidebar .sidebar-content-title {
  font-size: 0.85rem;
  color: #b9b9c3;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.app-calendar .app-calendar-sidebar .select-all ~ label,
.app-calendar .app-calendar-sidebar .input-filter ~ label {
  color: #303030;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.app-calendar .event-sidebar {
  position: absolute;
  top: 0;
  width: 400px;
  z-index: 15;
  transition: all 0.3s ease-in-out;
}
[dir=ltr] .app-calendar .event-sidebar {
  right: 0;
  transform: translateX(120%);
}
[dir=rtl] .app-calendar .event-sidebar {
  left: 0;
  transform: translateX(-120%);
}
.app-calendar .event-sidebar .card {
  height: calc(100vh - 12.96rem);
  height: calc(var(--vh, 1vh) * 100 - 12.96rem);
}
[dir=ltr] .app-calendar .event-sidebar .card {
  border-radius: 0 0.25rem 0.25rem 0;
}
[dir=rtl] .app-calendar .event-sidebar .card {
  border-radius: 0.25rem 0 0 0.25rem;
}
[dir] .app-calendar .event-sidebar .card .close-bar {
  cursor: pointer;
}
.app-calendar .event-sidebar .card .todo-item-action {
  width: 6rem;
}
.app-calendar .event-sidebar .card .todo-item-action .todo-item-info,
.app-calendar .event-sidebar .card .todo-item-action .todo-item-favorite,
.app-calendar .event-sidebar .card .todo-item-action .dropdown {
  line-height: 1.5;
}
[dir] .app-calendar .event-sidebar .card .todo-item-action .todo-item-info, [dir] .app-calendar .event-sidebar .card .todo-item-action .todo-item-favorite, [dir] .app-calendar .event-sidebar .card .todo-item-action .dropdown {
  cursor: pointer;
}
[dir] .app-calendar .event-sidebar .card .todo-item-action .dropdown .dropdown-menu .dropdown-item {
  padding: 0.14rem 1.428rem;
}
.app-calendar .event-sidebar .card .todo-item-action .dropdown-toggle::after {
  display: none;
}
[dir] .app-calendar .event-sidebar.show {
  transform: translateX(0);
}
.app-calendar .fc-toolbar h2 {
  font-size: 1.45rem;
}
[dir] .app-calendar .fc-header-toolbar {
  margin-bottom: 1.75rem !important;
}
[dir] .app-calendar .fc-view-harness {
  margin: 0 -1.6rem;
}
[dir] .app-calendar .fc-scrollgrid {
  border-color: #ebe9f1;
}
.app-calendar .fc-day-past .fc-daygrid-day-number,
.app-calendar .fc-day-future .fc-daygrid-day-number {
  color: #b9b9c3;
}
[dir] .app-calendar .fc-popover {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
[dir] .app-calendar .fc-popover .fc-popover-header {
  background: transparent;
  padding: 0.5rem;
}
.app-calendar .fc-popover .fc-popover-header .fc-popover-title,
.app-calendar .fc-popover .fc-popover-header .fc-popover-close {
  color: #303030;
}
[dir] .app-calendar .fc-popover .fc-popover-body *:not(:last-of-type) {
  margin-bottom: 0.3rem;
}
.app-calendar .fc .fc-event .fc-event-main {
  color: inherit;
}
[dir] .app-calendar .fc-list-event {
  background: transparent !important;
}
@media (min-width: 992px) {
.app-calendar .app-calendar-sidebar {
    position: static;
    height: auto;
}
[dir] .app-calendar .app-calendar-sidebar {
    box-shadow: none !important;
}
[dir] .app-calendar .app-calendar-sidebar .flatpickr-days {
    background-color: transparent;
}
}
.event-sidebar .select2-selection__choice__remove:before {
  top: 40% !important;
}
[dir] .horizontal-layout .app-calendar {
  margin-bottom: 1rem;
}
@media (max-width: 992px) {
.fc .fc-sidebarToggle-button {
    font-size: 0;
}
}
@media (min-width: 992px) {
.fc .fc-sidebarToggle-button {
    display: none;
}
}
@media (max-width: 700px) {
[dir] .app-calendar .fc .fc-header-toolbar .fc-toolbar-chunk:last-of-type {
    margin-top: 1rem;
}
}
.fc-sidebarToggle-button {
  width: 25px;
  height: 25px;
  display: block;
}
[dir] .fc-sidebarToggle-button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' stroke='%236e6b7b' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cline x1='3' y1='12' x2='21' y2='12'%3E%3C/line%3E%3Cline x1='3' y1='6' x2='21' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='18' x2='21' y2='18'%3E%3C/line%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
}
[dir=ltr] .fc-sidebarToggle-button {
  margin-right: 0.7rem !important;
}
[dir=rtl] .fc-sidebarToggle-button {
  margin-left: 0.7rem !important;
}
[dir] .fc-view-harness {
  margin: 0px !important;
}
.modal-new-lesson .modal-dialog {
  max-width: 590px;
}
.area-lessons {
  min-height: 400px;
}
[dir] .card-infos .card {
  margin-bottom: 0px;
}
.card-infos .card .card-header {
  display: flex;
}